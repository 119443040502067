//! css frameworks
// @import "../node_modules/bulma/bulma"; // css framework https://bulma.io/ - uncomment if you'd like to use it

//! utilities / helpers
@import "./styles/utils/mixins";
@import "./styles/utils/variables";

//! site-wide base styles
@import "./styles/base/setup"; /* normalize, clearfix, border-box, anti-aliasing, grey-scale */
@import "./styles/base/typography";
@import "./styles/base/global";

//! blocks
.page {
  position: relative;
  box-sizing: border-box;
  border-top: .5vh solid lightgreen;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Sarabun', sans-serif;
  font-weight: 300;
  color: #eee;
  background: linear-gradient(darken(blueviolet, 20), darken(blueviolet, 40));
  font-size: 21px;
  line-height: 1em * 1.63;

  @media only screen and (min-width: 500px) {
    font-size: 4.3vh;
  }

  &__canvas {
    pointer-events: none;
    position: absolute;
    opacity: .8;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__wrapper {
    width: 100%;
    padding: 6vh 4vh;

    @media only screen and (min-width: 600px) {
      max-width: 80vw;
    }

    @media only screen and (min-width: 1000px) {
      max-width: 60vw;
    }
  }

  &__inner {
    position: relative;
  }

  &__heading {
    color: lightgreen;
    font-size: 2.2em;
    font-weight: 700;
    line-height: 1em * 1.63;
    display: block;
  }

  &__lead {
  }

  &__footer {
    margin-top: 6vh;
  }

  &__strong {
    display: inline;
    font-weight: 700;
  }

  &__link {
    color: lightgreen;
    text-decoration: none;
    background-image: linear-gradient(120deg, lightgreen 0%, lightgreen 100%);
    background-repeat: no-repeat;
    background-size: 0 2px;
    background-position: 0 100%;
    transition: background-size .25s ease-in;

    &:hover {
      background-size: 100% 2px;
    }
  }

  &__icons {
    font-size: 26px;
    white-space: nowrap;

    @media only screen and (min-width: 600px) {
      font-size: 5vh;
    }

    &-item {
      display: inline-block;
      color: lightgreen;
      transition: 250ms;

      &:not(:first-child) {
        margin-left: 2vh;
      }

      &:hover {
        transform: rotateZ(-15deg);
      }
    }
  }

  &__legal {
    margin-top: 2vh;
    font-size: .6em;
    line-height: 1em * 1.63;
  }
}
