//? set your brand colors

$white: #fff;

$light-grey: #fafafa;
$grey: #d7d7d7;

$black-ish: #444;
$black: #09090e;

$blue: #2c638f;
$pink: #fff0f5;
$yellow: #fffacd;

//? override for project clarity

$color-primary: $blue;
$color-secondary: $pink;
$color-tertiary: $yellow;