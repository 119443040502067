//! import google fonts
// @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700');
// OR use @font-face like below

//! import a custom font below
@font-face {
  font-family: 'Libre-Baskerville-Reg';
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
  src:
    local('Libre Baskerville'), /* Checks if font is on users computer */
    local('LibreBaskerville-Regular'), /* Checks if font is on users computer */
    url('fonts/libre-baskerville-v5-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('fonts/libre-baskerville-v5-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

//! set font variables
$font-heading: 'Libre-Baskerville-Reg', Fallback, 'Georgia', serif;
$font-body: sans-serif;

@import url('https://fonts.googleapis.com/css?family=Sarabun:300,400');
@import url('https://use.fontawesome.com/releases/v5.7.0/css/all.css');
